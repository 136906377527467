@import "~aos/dist/aos.css";
@import "toastr/build/toastr.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

.blog-content ul {
    list-style-type: disc !important;
    padding: 0 15px;
}
.blog-content p, .blog-content span {
    color: #808080;
    font-size: 18px !important;
}
.blog-content h2 {
    color: #003060;
    font-size: 36px !important;
    font-weight: bold;
}
.blog-content h2 span, h2  p {
    color: #0E86D4 !important;
    font-size: 28px !important;
}
.blog-content blockquote, .blog-content blockquote > p {
    color: #0E86D4;
    font-weight: bold;
    font-style: italic;
    font-size: 24px;
}

.code-preformatted {
    white-space: pre-wrap;
    background-color: #EEE;
    font-family: "Courier 10 Pitch", Courier, monospace;
    margin: 2.9rem 0;
    max-width: 100%;
    overflow: auto;
    padding: 2.9rem;
}
.blog-content code {
    background-color: #EEE;
    padding: 3px 7px;
}
.particles-js-canvas-el {
    position: fixed;
    top: 0;
}
